import { Helmet } from 'react-helmet-async';
import { useParams, Link as RouterLink } from 'react-router-dom';
// @mui
import { Container, Typography, Box, Grid, Button } from '@mui/material';
import { formatUrl } from "../utils/formatUrl";


export default function HoeWerktHet() {

  const params = useParams();

  return (
    <>
      <Helmet>
        <title> Hoe werkt het? | Oorlogspad </title>
      </Helmet>

      <Container sx={{ py: 'clamp(24px, 5%, 112px)' }}>
        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 4, md: 5 }}>
          <Grid item xs={12} sm={8}>
            <Typography variant="h1" sx={{ mb: 2, fontSize: 'clamp(2rem, 1rem + 9vw, 5rem) !important' }}>
              Hoe werkt het?
            </Typography>
            <Typography variant="body1" sx={{ color: 'text.secondary', maxWidth: '600px', mb: 2 }}>
              Ga mee op Oorlogspad dwars door Gelderland en ontdek waargebeurde verhalen uit de Tweede Wereldoorlog. Stap in de voetsporen van onze bevrijders, verzetsmensen, vervolgden, vluchtelingen en de Duitse bezetter.
            </Typography>
            <Typography variant="body1" sx={{ color: 'text.secondary', maxWidth: '600px', mb: 2 }}>
              Tijdens de routes bekijk je video's, beluister je persoonlijke verhalen (nagespeeld), bekijk je vondsten in augmented reality en meer.
            </Typography>
            <Typography variant="body1" sx={{ color: 'text.secondary', maxWidth: '600px', mb: 6 }}>
              Ga naar het startpunt van een route, scan ter plekke de QR-code op het locatiebord en je bent op Oorlogspad met je gids Samir.
            </Typography>
            <Typography variant="body1" sx={{ color: 'text.secondary', maxWidth: '600px', mb: 4 }}>
              Let op! Een route heeft locatie- en cameratoestemming nodig om te functioneren. Accepteer deze wanneer daar om gevraagd wordt.
            </Typography>
            {params.routeId ?
              <Button to={`/qr/${params.routeId}`} variant="contained" sx={{ maxWidth: '480px !important', mb: 5, minWidth: '160px' }} component={RouterLink}>
                Terug naar route
              </Button> :
              <Button to="/" variant="contained" sx={{ maxWidth: '480px !important', mb: 5, minWidth: '160px' }} component={RouterLink}>
                Naar routes
              </Button>
            }
          </Grid>
          <Grid item xs={12} sm={4}>
            <Box
              component="img"
              src={formatUrl(`/assets/algemeen/images/oorlogspad-qr-code-voorbeeld.jpg`)}
              sx={{ width: '100%', mx: 'auto', boxShadow: '0px 8px 48px #000000', borderRadius: '12px' }}
            />
          </Grid>
        </Grid>
      </Container>
    </>
  );
}
