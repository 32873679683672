import { Helmet } from 'react-helmet-async';

// @mui
import { Stack, Grid, IconButton } from '@mui/material';

// components
import { useParams, useNavigate, useLocation } from "react-router-dom";

import useMediaQuery from '@mui/material/useMediaQuery';

import { useState, useRef, useEffect, useCallback } from 'react';
import { styled } from '@mui/material/styles';
import Iconify from '../components/iconify';
import { Content, ContentImage, ContentAudio, ContentVideo, ContentText, ContentPanorama, ContentPuzzle, ContentTap, ContentBeforeAfter, ContentQuiz, ContentShare, ContentPictureFinder, ContentMulti, ContentNavigate, ContentModel, ContentRouteComplete } from '../sections/@route/content';
import { route } from '../_mock/routes';
import { ContentType } from '../_mock/contentTypes';
import Page404 from './Page404';

import { AlertDialog } from '../components/dialog';

// ----------------------------------------------------------------------

const TopBarIconButton = styled(IconButton)(({ theme }) => ({
  width: '56px',
  padding: '16px',
  color: theme.palette.grey[400]
}));

const APP_BAR_HEIGHT = 56;

export default function ContentPage() {
  const params = useParams();
  const navigate = useNavigate();

  const isCompactHeight = useMediaQuery('(min-width:1200px)');

  const multiContentRef = useRef({});
  const contentRef = useRef({});


  const backIndex = useRef(0);
  const r = route(params.routeId);

  /*
  const c = (() => {
    if (r === undefined) {
      return <Page404 />;
    }
    else if (!Number.isNaN(parseInt(params.contentId, 10))) {
      if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
        return r.content.find(r => r.id === parseInt(params.contentId, 10));
      }
      else {
        return <Page404 />;
      }
    }
    else {
      return LoadContent();
    }
  })
  */

  const location = useLocation(); // used to read state {reset: true} param sent on QRPage and RoutePage
  const useResetRef = useRef(true);

  const [currentContent, setCurrentContent] = useState(LoadContent());

  const [skipDialogState, setSkipDialogState] = useState(false); // Skip Dialog
  const [exitDialogState, setExitDialogState] = useState(false); // Exit Dialog
  const [continueRouteState, setContinueRouteState] = useState((getRouteProgress() > 0)); // Continue Route Dialog

  const previousContent = getPreviousContent();
  const nextContent = getNextContent();

  function getPreviousContent() {
    const currentIndex = r.content.findIndex(c => c.id === parseInt(currentContent.id, 10));
    if (currentIndex > 0) {
      return r.content[currentIndex - 1];
    }
    return undefined;
  }

  function getNextContent() {
    const currentIndex = r.content.findIndex(c => c.id === parseInt(currentContent.id, 10));
    if (currentIndex < r.content.length - 1 && currentContent.type !== ContentType.RouteComplete && currentContent.type !== ContentType.RouteCompleteVideo) {
      return r.content[currentIndex + 1];
    }
    return undefined;
  }

  const setRouteProgress = useCallback((content) => {
    const progress = r.content.findIndex(c => c.id === content.id);
    // -1 if not found
    localStorage.setItem(r.id, progress);
  }, [r.content, r.id]);

  const next = useCallback(() => {
    if (nextContent !== undefined) {
      backIndex.current += 1;
      setRouteProgress(nextContent);
      window.scrollTo(0, 0);
      setCurrentContent(nextContent);
    }

  }, [nextContent, setRouteProgress]);

  const previous = useCallback(() => {
    if (previousContent !== undefined) {
      backIndex.current -= 1;
      window.scrollTo(0, 0);
      setCurrentContent(previousContent);
    }
  }, [previousContent]);


  const isInsideMultiContent = useCallback(() => {
    if (currentContent.type === ContentType.Multi && multiContentRef.current.selectedContent && multiContentRef.current.selectedContent.id >= 0) {
      return true;
    }
    else {
      return false;
    }
  }, [currentContent.type]);

  // on browser back button
  const back = useCallback(() => {

    if (contentRef.current && (typeof contentRef.current.requestBack === "function") && contentRef.current.requestBack()) {

      // content block intercepted back
    } else if (isInsideMultiContent()) {
      multiContentRef.current.returnToMenu();
    }
    // go back a content block
    else if (backIndex.current > 0) {
      window.history.pushState({}, '');
      previous();
    } else {
      // normal back button behaviour
      window.history.back();
    }


  }, [isInsideMultiContent, previous]);

  // cleanup back button event
  useEffect(() => () => {
    window.removeEventListener('popstate', back);
  }, [back]);

  // attach back button event
  useEffect(() => {
    window.history.pushState({}, '');
    window.removeEventListener('popstate', back);
    window.addEventListener('popstate', back);
  }, [back]);

  return <>
    <Helmet>
      <title> Oorlogspad - {r.name} </title>
    </Helmet>

    <Grid item xs={12}>
      <Stack justifyContent="space-between" sx={{ flexDirection: "row", height: `${APP_BAR_HEIGHT}px`, backgroundColor: currentContent.type === ContentType.Navigate ? 'background.alternative' : '' }}>
        <Stack sx={{ flexDirection: "row" }}>
          <TopBarIconButton aria-label="close to route overview" onClick={() => onExit()}>
            <Iconify icon="ic:baseline-close" width='100%' height='100%' />
          </TopBarIconButton>
        </Stack>
        <Stack sx={{ flexDirection: "row" }}>
          <TopBarIconButton aria-label="skip to previous content" disabled={(isFirst())} onClick={() => isInsideMultiContent() ? multiContentRef.current.returnToMenu() : onPrevious()}>
            <Iconify icon="ic:baseline-skip-previous" width='100%' height='100%' />
          </TopBarIconButton>
          <TopBarIconButton aria-label="skip to next content" disabled={isLast()} onClick={() => isInsideMultiContent() ? multiContentRef.current.returnToMenu() : onNext()}>
            <Iconify icon="ic:baseline-skip-next" width='100%' height='100%' />
          </TopBarIconButton>
        </Stack>
      </Stack>
    </Grid>
    <Grid sx={{ height: (isCompactHeight && currentContent.type !== ContentType.Navigate) ? '87vh' : `calc(100% - ${APP_BAR_HEIGHT}px)`, display: 'flex', flexDirection: 'column', justifyContent: 'unset', alignItems: 'center' }} item xs={12}>
      {(() => {
        switch (currentContent.type) {
          case ContentType.Image: return <ContentImage contentRef={contentRef} {...currentContent} onComplete={() => { onComplete(); }} />;
          case ContentType.Audio: return <ContentAudio {...currentContent} onComplete={() => { onComplete(); }} />;
          case ContentType.Video: return <ContentVideo {...currentContent} onComplete={() => { onComplete(); }} />;
          case ContentType.Text: return <ContentText {...currentContent} onComplete={() => { onComplete(); }} />;
          case ContentType.Panorama: return <ContentPanorama contentRef={contentRef} height={`calc(100vh - ${APP_BAR_HEIGHT}px)`} {...currentContent} onComplete={() => { onComplete(); }} />;
          case ContentType.Puzzle: return <ContentPuzzle {...currentContent} onComplete={() => { onComplete(); }} />;
          case ContentType.Tap: return <ContentTap {...currentContent} onComplete={() => { onComplete(); }} />;
          case ContentType.BeforeAfter: return <ContentBeforeAfter contentRef={contentRef} {...currentContent} onComplete={() => { onComplete(); }} />;
          case ContentType.Quiz: return <ContentQuiz {...currentContent} onComplete={() => { onComplete(); }} />;
          case ContentType.Share: return <ContentShare contentRef={contentRef} {...currentContent} onComplete={() => { onComplete(); }} />;
          case ContentType.PictureFinder: return <ContentPictureFinder {...currentContent} onComplete={() => { onComplete(); }} />;
          case ContentType.Multi: return <ContentMulti childContentRef={contentRef} {...currentContent} route={r} myRef={multiContentRef} onComplete={() => { onComplete(); }} />;
          case ContentType.Navigate: return <ContentNavigate {...currentContent} route={r} onComplete={() => { onComplete(); }} />;
          case ContentType.Model: return <ContentModel contentRef={contentRef} {...currentContent} onComplete={() => { onComplete(); }} />;
          case ContentType.RouteComplete: return <ContentRouteComplete {...currentContent} onComplete={() => { onComplete(); }} />;
          default: return <Content />
        }
      })()}
    </Grid>

    {/* Skip dialog */}
    <AlertDialog open={skipDialogState} handleClose={() => setSkipDialogState(false)} title="Overslaan"
      content="Weet je zeker dat je dit onderdeel wilt overslaan?"
      primaryButtonText="Overslaan" secondaryButtonText="Terug"
      primaryButtonFunc={() => { next(); }} secondaryButtonFunc={() => { }} />

    {/* exit dialog */}
    <AlertDialog open={exitDialogState} handleClose={() => setExitDialogState(false)} title="Terug naar routes"
      content="Weet je zeker dat je terug wilt gaan naar het routesoverzicht?"
      primaryButtonText="Naar routes" secondaryButtonText="Terug"
      primaryButtonFunc={() => { navigate(`/`, { replace: true }); }} secondaryButtonFunc={() => { }} />

    {/* continue route dialog */}
    <AlertDialog open={continueRouteState} handleClose={() => setContinueRouteState(false)} title="Route vervolgen"
      content="Wil je de route vervolgen vanaf je laatste locatie?"
      primaryButtonText="Start vanaf begin" secondaryButtonText="Verder"
      primaryButtonFunc={() => { removeProgress(); setCurrentContent(r.content[0]) }} secondaryButtonFunc={() => { }} />
  </>

  function isFirst() {
    return previousContent === undefined
  }

  function isLast() {
    return nextContent === undefined
  }

  function onNext() {
    setSkipDialogState(true);
  }

  function onPrevious() {
    previous();
  }

  function onExit() {
    setExitDialogState(true);
  }

  function getRouteProgress() {
    const progress = localStorage.getItem(r.id);
    const num = Number(progress);
    if (Number.isInteger(num) && num >= 0 ) {
      return num;
    }
    else {
      return 0;
    }
  }

  function removeProgress() {
    localStorage.removeItem(r.id);
    // remove any saves for ContentMulti
    for (let i = 0; i < r.content.length; i += 1) {
      if (r.content[i].type === ContentType.Multi) {
        localStorage.removeItem(`${r.id}-${r.content[i].id}`);
      }
    }
  }

  function LoadContent() {
    const progress = getRouteProgress();

    // check if prev state is from QR, to always start from 0
    if (useResetRef.current && location.state && location.state.reset) {
      useResetRef.current = false;
      console.log("reset route coming from QR")
      if (progress > 0) {
        removeProgress();
      }
      return r.content[0];
    }
    return r.content[progress];
  }

  function onComplete() {
    next();
  }
}
