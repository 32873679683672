/* eslint-disable react/prop-types */

import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import { useState, useEffect, useCallback } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import { Dialog, DialogActions, DialogTitle, DialogContent, Stack, Box, styled } from '@mui/material';




export const CookieConsent = () => {
  const [visible, setVisible] = useState(false);
  const [optionsVisible, setOptionsVisible] = useState(false);
  const [categories, setCategories] = useState({});

  const [initialized, setInitialized] = useState(false);


  const StyledSwitch = styled(Switch)(({ theme }) => ({
    '& .MuiSwitch-track': {
      backgroundColor: theme.palette.divider, // Background when off
    },
    /*
    '&.Mui-checked .MuiSwitch-track': {
      backgroundColor: theme.palette.primary.main, // Background when on
    }, */
  }));

  const initCategories = [
    {
      key: "necessary",
      title: "Functionele cookies (verplicht)",
      description: "Functionele cookies hebben een functionele rol binnen de website. De cookies zorgen ervoor dat de website goed functioneert.",
      isNecessary: true
    },
    {
      key: "analytics",
      title: "Analytische cookies",
      description: "Analytische cookies geven ons inzicht in hoe onze website wordt gebruikt. Op basis van deze informatie kunnen wij onze website gebruiksvriendelijker maken."
    },

    {
      key: "marketing",
      title: "Marketing (uitgeschakeld)",
      description: "Marketing cookies maken het mogelijk om een profiel op te bouwen van jouw online gedrag en om jou gepersonaliseerde advertenties te laten zien.",
      disabled: true,
    }
  ];

  const applyGtmConsent = useCallback((data) => {
    // uppdate google tag manager consent
    const dataLayer = window.dataLayer || [];
    function gtag() {
      // eslint-disable-next-line prefer-rest-params
      dataLayer.push(arguments);
    }
    gtag('consent', 'update', {
      ad_storage: 'denied',
      ad_user_data: 'denied',
      analytics_storage: data && data.analytics ? 'granted' : 'denied',
      personalization_storage: 'denied',
      functionality_storage: 'granted',
      security_storage: 'denied',
    });
    console.log("Consent preference loaded");
  }, []);


  const showDialog = useCallback(() => {
    setOptionsVisible(false);
    setVisible(true); // sets a useState variable
  }, []);


  useEffect(() => {
    window.addEventListener('consentTrigger', showDialog)

    return () => {
      window.removeEventListener('consentTrigger', showDialog);
    }

  }, [showDialog]);


  useEffect(() => {
    if (!initialized) {
      const consentData = localStorage.getItem('consent');
      if (!consentData || consentData === null) {
        setVisible(true);
      } else {
        const parsed = JSON.parse(consentData);

        if (typeof parsed === 'object' && !Array.isArray(parsed) && parsed !== null) {
          setCategories(parsed);
          // localstorage gtm already applied in index.html
        }
      }
      setInitialized(true);
    }
  }, [applyGtmConsent, initialized]);


  const saveConsent = (data) => {
    localStorage.setItem('consent', JSON.stringify(data));

    setVisible(false);
    applyGtmConsent(data);
  }



  const handleOptionsClick = () => {
    setOptionsVisible(true);
  };

  const handleDeny = () => {
    const newCategories = { ...categories };
    initCategories.forEach(
      (category) => { newCategories[category.key] = Object.prototype.hasOwnProperty.call(category, "isNecessary") && category.isNecessary }
    );
    setCategories(newCategories);
    saveConsent(newCategories);
  };

  const handleAcceptCurrent = () => {
    saveConsent(categories);
  };

  const handleAcceptAll = () => {
    const newCategories = { ...categories };
    initCategories.forEach(
      (category) => { newCategories[category.key] = !category.disabled && true; }
    );
    setCategories(newCategories);
    saveConsent(newCategories);
  };

  const handleChange = (event) => {
    const newCategories = { ...categories };
    newCategories[event.target.name] = event.target.checked;
    setCategories(newCategories);
  };



  return (
    <Dialog open={visible} scroll="paper" sx={{
      minWidth: { xs: '100%', sm: '600px' },
      "& .MuiDialog-container": {
        alignItems: "center",
      },
      '.MuiPaper-root': {
        padding: { xs: '4px', sm: '16px' },
        margin: '4px',
      },
    }}
      /* hideBackdrop */

      PaperProps={{ sx: {} }}

    >
      {!optionsVisible && (
        <>
          <DialogTitle>Cookies</DialogTitle>
          <DialogContent>
            <Typography variant="body1" component={'div'} >
              <p>
                We gebruiken functionele en analytische cookies om onze website optimaal te laten functioneren, voorkeuren te bewaren en bezoekersgedrag te analyseren. Je kunt je cookievoorkeuren aanpassen via "voorkeuren aanpassen".
              </p>


              <p> Geef hier aan welke cookies je wilt accepteren. </p>
              Voor meer informatie, zie <Link to="/settings/privacy" onClick={handleDeny} component={RouterLink} >Privacy en Cookies</Link>.

            </Typography>
          </DialogContent>
          <DialogActions>
            <Stack
              direction={{
                xs: 'column', // Column direction for extra-small screens
                sm: 'row',    // Row direction for small screens and up
              }}
              spacing={2}
              sx={{ width: '100%' }}
            >
              <Button variant="text" onClick={handleOptionsClick}>
                <Typography variant="body1"  >
                  Voorkeuren aanpassen
                </Typography>
              </Button>

              <Box sx={{ flexGrow: '1' }} />
              <Button
                variant="outlined"
                color="primary"
                onClick={handleDeny}
                sx={{ display: { xs: 'none', sm: 'unset' } }}
              >
                <Typography variant="body1" >
                  Alleen noodzakelijk
                </Typography>
              </Button>
              <Button
                variant="contained"
                color="primary"
                onClick={handleAcceptAll}
              >
                <Typography variant="body1" >
                  Accepteren
                </Typography>
              </Button>
              <Button
                variant="outlined"
                color="primary"
                onClick={handleDeny}
                sx={{ display: { xs: 'unset', sm: 'none' } }}
              >
                <Typography variant="body1" >
                  Alleen noodzakelijk
                </Typography>
              </Button>
            </Stack>
          </DialogActions>
        </>
      )}
      {optionsVisible && (
        <>
          <DialogTitle>Cookievoorkeuren</DialogTitle>
          <DialogContent>
            <Typography variant="body1" sx={{ pb: '12px' }} >
              Kies welke type cookies je wilt accepteren. <br />
              Voor meer informatie, zie de <Link to="/settings/privacy" onClick={handleDeny} component={RouterLink} >Privacy en Cookies</Link>.
            </Typography>
            <div>
              {initCategories?.map((category, index) => (

                <div key={index}>
                  <FormControlLabel
                    control={
                      <StyledSwitch
                        disabled={category.disabled}
                        checked={
                          category.isNecessary ??
                          categories[category.key] ??
                          false
                        }
                        onChange={
                          category.isNecessary ?? false
                            ? undefined
                            : handleChange
                        }
                        name={category.key}
                        color="primary"
                      />
                    }
                    label={
                      <Typography variant="body1" fontWeight={'bold'} sx={{ opacity: category.disabled ? 0.5 : 1 }} >
                        {category.title}
                      </Typography>
                    }
                    labelPlacement="end"
                  />
                  <Typography variant="body2" sx={{ pb: '12px', opacity: category.disabled ? 0.5 : 1 }} >
                    {category.description}
                  </Typography>
                </div>
              ))}
            </div>
          </DialogContent>
          <DialogActions>
            <Box marginRight="auto">
              <Button variant="text" onClick={() => { setOptionsVisible(false); }} size="small">
                <Typography variant="body1" >
                  Terug
                </Typography>
              </Button>
            </Box>
            <Button variant="contained" onClick={handleAcceptCurrent}>
              <Typography variant="body1" >
                Voorkeuren toepassen
              </Typography>
            </Button>
          </DialogActions>
        </>
      )}
    </Dialog>
  );
};

export default CookieConsent;