import { Helmet } from 'react-helmet-async';
import { useState } from 'react';
import Link from '@mui/material/Link';
// @mui
import { Container, Typography, Button, Stack } from '@mui/material';
import { AlertDialog } from '../components/dialog';



export default function PrivacyPage() {

  const [storageDialogOpen, setStoragedialogOpen] = useState(false);

  const deleteStorageAndCookies = () => {

    localStorage.clear();
    sessionStorage.clear();

    // modern cookie delete, no safari support
    if (("cookieStore" in window)) {
      try {
        window.cookieStore.getAll()
          .then(cookies => cookies.forEach(cookie => {
            window.cookieStore.delete(cookie.name);
            window.cookieStore.delete(cookie);
          }));
        // eslint-disable-next-line no-empty
      } catch { }

    }


    // delete Google analytics cookies the classic way. assumes root domain and empty path
    const cookies = document.cookie.split(';');
    cookies.forEach((cookie) => {
      const cookieName = cookie.trim().split('=')[0];
      document.cookie = `${cookieName}=; Max-Age=0; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/; domain=${window.location.hostname}`;
    });
  };


  return (
    <>
      <style>
        {`
        table, th, td {
          border: 1px solid rgba(255,255,255, 0.1);
          border-collapse: collapse;
          background-color: rgba(255,255,255, 0.02);
        }
        th, td{
          padding: 0px 8px;

        }
        thead {
          font-weight: bold;
        }
        ul {
          margin-left: 32px;
        }
        `}
      </style>
      <Helmet>
        <title> Privacy & Cookies | Oorlogspad </title>
      </Helmet>

      <Container sx={{ pa: "12px", py: 'clamp(24px, 5%, 112px)' }}>
        <Stack direction="column" spacing={2} sx={{ maxWidth: '800px' }}>
          <Typography variant="h1" sx={{ fontSize: 'clamp(2rem, 1rem + 9vw, 5rem) !important' }}>
            Cookie instellingen
          </Typography>
          <Typography variant="body1" sx={{ color: 'text.secondary' }}>
            Met de onderstaande knop kan je de cookiemelding opnieuw openen om je voorkeuren aan te passen.
          </Typography>
          <Button onClick={() => { window.dispatchEvent(new Event('consentTrigger')) }} variant="contained" sx={{ maxWidth: '480px !important', minWidth: '160px' }} >
            Cookie instellingen
          </Button>
          <Typography variant="body1" sx={{ color: 'text.secondary' }}>
            Met de onderstaande knop kan je alle data die Oorlogspad in je webbrowser heeft opgeslagen verwijderen.<br/>Dit kan ook altijd via de instellingen van je webbrowser, zie daarvoor bijvoorbeeld deze <Link target='_blank' rel="noopener noreferrer" href="https://www.consumentenbond.nl/internet-privacy/cookies-verwijderen">handleiding van de Consumentenbond</Link>.
          </Typography>
          <Button onClick={() => { setStoragedialogOpen(true) }} variant="outlined" sx={{ maxWidth: '480px !important', minWidth: '160px' }} >
            Cookies en lokale data verwijderen
          </Button>



          <Typography variant="h1" sx={{ pt: '64px', fontSize: 'clamp(2rem, 1rem + 9vw, 5rem) !important' }}>
            Privacy
          </Typography>
          <Typography variant="body1" sx={{ color: 'text.secondary' }}>
            Deze pagina is voor het laatst bewerkt op 09-12-2024.
            <br /><br />

            Oorlogspad is een product van Toerisme Veluwe Arnhem Nijmegen. <br />
            Toerisme Veluwe Arnhem Nijmegen B.V., gevestigd aan de Dorpstraat 14, 6661 EK Elst is verantwoordelijk voor de verwerking van persoonsgegevens zoals weergegeven in deze privacyverklaring. In deze privacyverklaring is vastgelegd hoe Toerisme Veluwe Arnhem Nijmegen omgaat met persoonsgegevens, waar ze voor gebruikt worden en welke maatregelen we nemen om deze te beschermen. Wanneer je hier vragen over hebt kan je contact opnemen met info@toerismevan.nl.
          </Typography>


          <Typography variant="body1" component={'div'} sx={{ color: 'text.secondary' }}>

            <h2>Persoonsgegevens die Toerisme Veluwe Arnhem Nijmegen verwerkt</h2>
            <p>Toerisme Veluwe Arnhem Nijmegen verwerkt persoonsgegevens doordat je gebruik maakt van onze diensten en/of omdat je gegevens aan ons hebt verstrekt.</p>
            <p>Onderstaand een overzicht van de gegevens die wij mogelijk middels deze dienst/website verzamelen:</p>

            <h3>Oorlogspad website:</h3>

            <ul>
              <li>Ip-adres (geanonimiseerd)</li>
            </ul>

            <h3> Feedbackformulier</h3>
            <p>Aan het eind van een route kan je optioneel een Google Forms enquête invullen. Hierin verzamelen we de onderstaande persoonsgegevens</p>
            <ul>
              <li>Voornaam</li>
              <li>Achternaam</li>
              <li>Leeftijd</li>
              <li>Woonplaats</li>
              <li>Email</li>
            </ul>



            <h2>Met wie deelt Toerisme Veluwe Arnhem Nijmegen deze persoonsgegevens</h2>
            <p>Toerisme Veluwe Arnhem Nijmegen gebruikt de persoonsgegevens voor de eigen bedrijfsvoering. Soms kan het nodig zijn om deze persoonsgegevens te delen met organisaties die voor ons werkzaamheden verrichten of waarmee we samenwerken.</p>
            <p><em>Google Analytics<br />
            </em>
              Met behulp van Google Analytics kunnen we informatie verzamelen over de bezoeken aan en het gebruik van deze website/dienst. Toerisme Veluwe Arnhem Nijmegen heeft met Google een bewerkersovereenkomst afgesloten. De gegevens die worden verzameld door Google worden geanonimiseerd en versleuteld. Het delen van deze gegevens met andere Google-diensten staat uit.</p>
            <p>Google Analytics mag alleen cookies plaatsen als je "Analytische cookies" hebt geaccepteerd.<br />
              Google verzamelt met analytische cookies jouw IP-adres maar deze wordt direct geanonimiseerd. Meer informatie lees je in de <Link target='_blank' rel="noopener noreferrer" href="https://support.google.com/analytics/answer/6004245?hl=nl">privacyverklaring van Google</Link>. Deze verklaring kan regelmatig wijzigen.
            </p>

            <h2>Hoe lang worden deze gegevens bewaard</h2>
            <p>Persoonsgegevens mogen niet langer bewaard worden dan noodzakelijk is. De bewaartermijn is afhankelijk van het doel waarvoor we de gegevens hebben gekregen of verzameld en van eventuele wettelijke plichten om gegevens te bewaren. Indien je vragen hebt over specifieke gegeven neem dan even contact op met&nbsp;<Link target='_blank' rel="noopener noreferrer" href="mailto:info@toerismevan.nl">info@toerismevan.nl</Link>.</p>

            <h2>Gegevens inzien, aanpassen en verwijderen</h2>
            <p>Als bezoeker van de website maak je gebruik van een dienst. Je hebt het recht op je eigen gegevens in te zien, aan te laten passen of te laten verwijderen. Dit betekent dat je een verzoek kan indienen voor inzage, correctie of verwijdering. Dit verzoek mag je sturen naar <Link target='_blank' rel="noopener noreferrer" href="mailto:info@toerismevan.nl">info@toerismevan.nl</Link> Geef hierbij aan om welke dienst of website het gaat.</p>
            <p>Wel willen we er zeker van zijn dat het verzoek door jou is gedaan. Ter bescherming van ieders privacy vragen we wellicht om aanvullende documentatie die aantoont dat het verzoek door jou is gedaan. We reageren zo snel mogelijk, maar uiterlijk binnen vier weken op je verzoek.</p>


            <h2>Welke cookies gebruikt Oorlogspad</h2>
            <p>Een cookie is een klein tekstbestand die op je computer, smartphone of ander digitaal apparaat wordt opgeslagen. Onderstaand hebben wij weergegeven welke cookies op je apparaat worden geplaatst door gebruik te maken van deze dienst/website.</p>
            <table>
              <thead>
                <tr>
                  <td>Naam</td>
                  <td>Soort</td>
                  <td>Aanbieder</td>
                  <td>Doel</td>
                  <td>Vervaldatum</td>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>_ga</td>
                  <td>Analytisch</td>
                  <td>Google Analytics</td>
                  <td>Onderscheiden van unieke bezoekers</td>
                  <td>2 jaar</td>
                </tr>
                <tr>
                  <td>_ga_&lt;id&gt;</td>
                  <td>Analytisch</td>
                  <td>Google Analytics</td>
                  <td>Sessiestatus behouden</td>
                  <td>2 jaar</td>
                </tr>
              </tbody>
            </table>
            <p>Naast deze analytische cookies wordt er nog tekst opgeslagen in de zogenoemde "Local Storage" van je webbrowser. Deze functionele en anonieme data staat lokaal op je apparaat en wordt nooit over internet gedeeld met Oorlogspad of een andere partij. <br /> Hiermee herstellen we bijvoorbeeld je routevoortgang als je de pagina herlaadt en bewaren we je cookievoorkeuren. Deze data heeft geen vervaldatum en kan je verwijderen met de knop bovenaan deze pagina.</p>


            <h2>Welke hardwarefuncties gebruikt Oorlogspad</h2>
            <p>Deze hardwarefunctionaliteiten worden, waar mogelijk, gebruikt voor het functioneren van spelonderdelen. Deze data wordt niet opgeslagen of gedeeld met derde partijen. </p>
            <table>
              <thead>
                <tr>
                  <td>Type</td>
                  <td>Functie</td>
                  <td>Gegevens delen</td>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Camera</td>
                  <td>Augmented reality en foto maken. </td>
                  <td>Niet gedeeld of opgeslagen. De gebruiker kan deze handmatig downloaden of delen.</td>
                </tr>
                <tr>
                  <td>Gyroscoop</td>
                  <td>Orientatie telefoon voor kompas en Augmented reality.</td>
                  <td>Niet gedeeld of opgeslagen.</td>
                </tr>
                <tr>
                  <td>Locatie(GPS)</td>
                  <td>Kompas en Kaart voor navigatie</td>
                  <td>De exacte locatie wordt niet gedeeld of opgeslagen. Mogelijk kan Google Maps bij het tonen van een kaart zien welke kaartregio is ingeladen door een IP-adres.</td>
                </tr>
              </tbody>
            </table>

            <h2>Recht op indienen van een klacht</h2>
            <p>Indien je als bezoeker van deze website/dienst bezwaar wil maken tegen de wijze waarop Toerisme Veluwe Arnhem Nijmegen jouw persoonsgegevens verwerkt dan kan je een klacht indienen bij de Autoriteit Persoonsgegevens. Meer informatie daarover is te vinden op www.autoriteitpersoonsgegevens.nl.</p >

            <h2>Contact</h2>
            <p>Indien je vragen of opmerkingen hebt over privacy en cookies dan kan je contact opnemen met Toerisme Veluwe Arnhem Nijmegen via <Link target='_blank' rel="noopener noreferrer" href="mailto:info@toerismevan.nl">info@toerismevan.nl</Link>.</p>



          </Typography>
        </Stack >
      </Container >


      <AlertDialog open={storageDialogOpen} handleClose={() => setStoragedialogOpen(false)} title="Data verwijderen"
        content="Wil je de alle in je browser opgeslagen data zoals analytische cookies, routevoortgang en cookievoorkeur verwijderen?"
        primaryButtonText="Verwijderen" secondaryButtonText="Annuleren"
        primaryButtonFunc={() => { deleteStorageAndCookies(); setStoragedialogOpen(false); }} secondaryButtonFunc={() => { setStoragedialogOpen(false); }} />
    </>
  );
}
